export const ASSIGNMENT_SLICE = 'assignmentSlice'
export const ASSIGNMENT_API_URL = 'assignments'
export const CHANGE_ASSIGNMENT_STATUS = 'change-assignment-status'
export const MARK_ASSIGNMENT = 'mark-assignment'
export const RE_ASSIGN_ASSIGNMENT = 're-assign-assignment'
export const SAVE_ASSIGNMENT_COMMENT = 'save-assignment-comment'
export const GET_ASSIGNMENT_COMMENTS = 'get-assignment-comments'
export const GET_ASSIGNMENT_TYPES = 'assignment-types'
export const ASSIGNMENT_ATTACHMENT_API_URL = 'assignment-attachment'
export const UPDATE_ASSIGNMENT = 'update-assignment'
export const SINGLE_STUDENT_SAVE_RUBRIC_MARKS = 'single-student-save-rubric-marks'
export const REMOVE_SINGLE_ASSIGNEE = 'remove-single-assignee'
export const ADD_MORE_STUDENTS_INTO_ASSIGNMENT = 'add-more-students-into-assignment'
export const REMOVE_SINGLE_RUBRIC = 'remove-single-rubric'
export const ADD_MORE_RUBRICS_INTO_ASSIGNMENT = 'add-more-rubrics-into-assignment'
export const DELETE_ASSIGNMENT = 'delete-assignment'

// export const getAllAssignmentsEP = '/assignments'
// export const addAssignmentEP = '/assignments'
// export const updateAssignmentEP = '/update-assignment'
// export const changeAssignmentStatusEP = '/change-assignment-status'
// export const markAssignmentEP = '/mark-assignment'
// export const reAssignAssignmentEP = '/re-assign-assignment'
// export const saveAssignmentCommentEP = '/save-assignment-comment'
// export const getAssignmentCommentsEP = '/get-assignment-comments'

